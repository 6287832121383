import React from 'react'
import "../styling/Invoice.css"
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import DropDowns from './Superadmin/DropDowns'



function InvoicForm() {
  return (
    <div className='invoicefrom'>
      <div className="invoiceform__container">
        <h3 style={{marginBottom: '2rem'}}>Add Bill Record</h3>

        <a href='/su/viewInvoice'><button  className='std-button-sun' style={{marginBottom: '2rem'}}> View Records</button></a>


        <div className="invoiceform__container__form">
          <form >

            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{fontWeight:"600", fontSize:"20px"}}>ImgUrl:</TableCell>
                  <TableCell >
                   <input style={{ padding:"3px"}} type="url" required />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell  style={{fontWeight:"600", fontSize:"20px"}}>Car Model:</TableCell>
                  <TableCell> <DropDowns /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell  style={{fontWeight:"600", fontSize:"20px"}}>InvNumber:</TableCell>
                  <TableCell><input type="text" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell  style={{fontWeight:"600", fontSize:"20px"}}>InvVal:</TableCell>
                  <TableCell><input type="text" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell  style={{fontWeight:"600", fontSize:"20px"}}>Username:</TableCell>
                  <TableCell><input type="text" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell  style={{fontWeight:"600", fontSize:"20px"}}>ClientName:</TableCell>
                  <TableCell> <DropDowns /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell  style={{fontWeight:"600", fontSize:"20px"}}>Account Name:</TableCell>
                  <TableCell> <DropDowns /> </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell  style={{fontWeight:"600", fontSize:"20px"}}>Approved by admin:</TableCell>
                  <TableCell> <DropDowns /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell  style={{fontWeight:"600", fontSize:"20px"}}>Generated by agent:</TableCell>
                  <TableCell> <DropDowns /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{fontWeight:"600", fontSize:"20px"}}>InvHiddenId:</TableCell>
                  <TableCell ><input type="text" /></TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div className="invoiceform__container__form__add">
              <button className='std-button-sun'>ADD</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default InvoicForm