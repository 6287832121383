const common={
  // baseUrl:"https://mbs-aa-backend.herokuapp.com/" 
  // baseUrl: 'https://mbs-aa-staging.herokuapp.com/'
  // baseUrl:"http://3.110.249.10:8000/"  
  // baseUrl:"https://mbs-backend.ga/"
  baseUrl:"https://mbs-crm-backend.herokuapp.com/",
  // baseUrl:"https://mbs-crm-staging.herokuapp.com/",
  webUrl:"https://bugs.d102029m2o7zzt.amplifyapp.com/"
  // webUrl:"https://www.mbsautoavenue.com/"

}

export default common;
